import { useState } from "react";
const Input = ({
  field_id,
  fieldLabel,
  mappedEntityFieldId,
  data,
  setData,
  isMandatoryField,
}) => {
  const [val, setVal] = useState();

  const handleChange = (name) => (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };
  return (
    <div className="form-group ">
      <label htmlFor={field_id}>{fieldLabel}</label>
      {isMandatoryField == true ? (
        <input
          type="text"
          className={
            data[mappedEntityFieldId]
              ? "form-control"
              : "form-control is-invalid"
          }
          id={"form-field-" + field_id}
          name={mappedEntityFieldId}
          aria-describedby={"form-field-" + field_id + "Feedback"}
          value={data[mappedEntityFieldId]}
          onChange={handleChange(mappedEntityFieldId)}
        />
      ) : (
        <input
          type="text"
          className="form-control"
          id={"form-field-" + field_id}
          name={mappedEntityFieldId}
          aria-describedby={"form-field-" + field_id + "Feedback"}
          value={data[mappedEntityFieldId]}
          onChange={handleChange(mappedEntityFieldId)}
        />
      )}
      <div id={"form-field-" + field_id + "Feedback"} class="invalid-feedback">
        Please provide a valid {fieldLabel}
      </div>
      <p>{val}</p>
    </div>
  );
};
export default Input;
