import { useState } from "react";
const DatePicker = ({
  field_id,
  fieldLabel,
  mappedEntityFieldId,
  data,
  setData,
  isMandatoryField,
}) => {
  const [val, setVal] = useState();

  function convertDateFormat(dateString) {
    if (dateString && dateString != "") {
      var parts = []
      parts = dateString.split('-');
      if (dateString.includes("/")) {
        parts = dateString.split('/');
      }
      if (parts.length == 3) {
        var formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
        return formattedDate;
      }
    }
  }

  const handleChange = (name) => (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: convertDateFormat(value) });
  };

  return (
    <div className="form-group ">
      <label htmlFor={field_id}>{fieldLabel}</label>
      {isMandatoryField == true ? (
        <input
          type="date"
          className={
            data[mappedEntityFieldId]
              ? "form-control"
              : "form-control is-invalid"
          }
          id={"form-field-" + field_id}
          name={mappedEntityFieldId}
          aria-describedby={"form-field-" + field_id + "Feedback"}
          defaultValue={convertDateFormat(data[mappedEntityFieldId])}
          onChange={handleChange(mappedEntityFieldId)}
        />
      ) : (
        <input
          type="date"
          className="form-control"
          id={"form-field-" + field_id}
          name={mappedEntityFieldId}
          aria-describedby={"form-field-" + field_id + "Feedback"}
          onChange={handleChange(mappedEntityFieldId)}
          defaultValue={convertDateFormat(data[mappedEntityFieldId])}
        />
      )}
      <div id={"form-field-" + field_id + "Feedback"} className="invalid-feedback">
        Please provide a valid {fieldLabel}
      </div>
      <p>{val}</p>
    </div>
  );
};
export default DatePicker;
