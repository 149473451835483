import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import axios from "axios";

export default function Loginform() {
  const [signin, setSignin] = useState({
    username: "",
    password: "",
  });

  const [passwordShown, setpasswordShown] = useState(false);
  let [loading, setLoading] = useState(false);

  const togglePassword = () => {
    setpasswordShown(!passwordShown);
  };

  const navigate = useNavigate();

  const handleChange = (name) => (event) => {
    const { name, value } = event.target;
    setSignin({ ...signin, [name]: value });
  };

  const toastOptions = {
    position: "bottom-right",
    autoClose: 4000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const handleValidation = () => {
    const { username, password } = signin;
    if (username.length == " " && password.length == " ") {
      toast.error("User Name & Password is required", toastOptions);
      return false;
    }
    if (username.length == " ") {
      toast.error("User Name is required", toastOptions);
      return false;
    }
    if (password.length == " ") {
      toast.error("Password is required", toastOptions);
      return false;
    } else if (password.length < 8) {
      toast.error(
        "Password should be equal or greater than 8 character",
        toastOptions
      );
      return false;
    }

    return true;
  };

  const onKeyPress = (e) => {
    console.log("on keypress function called");
    if (e.which === 13) {
      onSignin();
    }
  };

  const onSignin = async () => {
    if (handleValidation()) {
      let result = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/signin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            username: signin.username,
            password: signin.password,
          }),
        }
      );
      result = await result.json();
      localStorage.setItem("user_info", JSON.stringify(result));
      console.log("result", result.status);

      if (result.status == 401) {
        localStorage.clear("user_info");
        navigate("/");
        console.log("due to 401 error you navigate  to login page again");
        toast.error("Wrong Username/Password", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/organization-member/${result.id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization:
                  "Bearer " +
                  JSON.parse(localStorage.getItem("user_info")).accessToken,
              },
            }
          )
          .then((response) => {
            let organization_data = response.data;
            navigate(`/projects/${organization_data.organizationId}`);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <>
      <div className="maincontainer">
        <div className="container-fluid">
          <div className="row no-gutter" style={{ height: "100vh" }}>
            <div className="col-md-6 d-none d-md-flex " id="backcolor">
              <div className="row">
                <h1
                  style={{
                    color: "white",
                    fontFamily: "Alfa Slab One",
                    fontSize: "61px",
                  }}
                >
                  INSTANTANEOUS
                </h1>
                <p
                  style={{
                    color: "white",
                    fontFamily: "Alfa Slab One",
                    fontSize: "25px",
                    textAlign: "center",
                  }}
                >
                  HELPS YOU TO DIGITIZE YOUR BUSINESS INSTANTLY
                </p>
              </div>
            </div>

            <div className="col-md-6" style={{ backgroundColor: "#F0F8FF" }}>
              <div
                className="box shadow bg-white p-4 rounded-5"
                style={{
                  marginTop: "15%",
                  width: "70%",
                  height: "70%",
                  marginLeft: "15%",
                }}
              >
                <div className="mb-4 text-center fs-1"></div>
                <h5 className="mb-4 text-center fs-1">Login</h5>
                <form className="mb-3">
                  <div className="form-floating mb-3 ">
                    <input
                      type="email"
                      className="form-control rounded-5"
                      id="username"
                      name="username"
                      value={signin.username}
                      onChange={handleChange("username")}
                      onKeyDown={onKeyPress}
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                  <div className="form-floating mb-3 ">
                    <VisibilityIcon
                      onClick={togglePassword}
                      fontSize="small"
                      id="togglebtnposition"
                    />
                    <input
                      className="form-control mt-4 rounded-5"
                      id="password"
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      value={signin.password}
                      onChange={handleChange("password")}
                      onKeyDown={onKeyPress}
                    />
                    <label htmlFor="password">Password</label>
                  </div>

                  <div className="d-grid gap-3 mt-4 mb-3">
                    <button
                      type="button"
                      className="btn btn-light btn-lg border-0 rounded-5"
                      style={{
                        backgroundColor: "#0093E9",
                        backgroundImage: `linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)`
                      }}
                      onClick={onSignin}
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={5000} />
      </div>
    </>
  );
}
