const TextArea = ({ field_id, fieldLabel, mappedEntityFieldId , data,
  setData}) => {

    const handleChange = (name) => (event) => {
      const { name, value } = event.target;
      setData({ ...data, [name]: value });
    };

  return (
    <div className="form-group">
      <label htmlFor="exampleFormControlTextarea1">{fieldLabel}</label>
      <textarea
        className="form-control"
        id="exampleFormControlTextarea1"
        rows="3"
        name={mappedEntityFieldId}
        value={data[mappedEntityFieldId]}
        onChange={handleChange(mappedEntityFieldId)}
      >
        {mappedEntityFieldId}
      </textarea>
    </div>
  );
};
export default TextArea;
