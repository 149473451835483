import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Header({ project_details }) {
  let [loading, setLoading] = useState(false);
  let [projects, setProjects] = useState([]);
  let [projectId, setProjectId] = useState([]);

  const navigate = useNavigate();

  const navigateMainPage = (project_id) => {
    navigate(`/main/${project_id}`);
    setProjectId(project_id);
    window.location.reload(false);
  };

  useEffect(() => {
    getUserProjectsData();
  }, []);

  // List Projects
  const getUserProjectsData = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/project/user/${
          JSON.parse(localStorage.getItem("user_info")).id
        }?page=0`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_info")).accessToken,
          },
        }
      )
      .then((response) => {
        setProjects(response.data.content);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleChange = (event) => {
    navigateMainPage(event.target.value);
  };

  return (
    <header className="navbar navbar-light sticky-top bg-light flex-md-nowrap p-0">
      {/* <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" href="#">
        {project_details ? project_details.name : ""}
      </a> */}
      <button
        className="navbar-toggler position-absolute d-md-none collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <select
        className="form-select"
        labelId="projectId"
        id="projectId"
        value={project_details ? project_details.id : ""}
        defaultValue={project_details ? project_details.id : ""}
        onChange={(e) => handleChange(e)}
        style={{ width: "20%", marginLeft: "auto" }}
      >
        {projects.map((project) => {
          return (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          );
        })}
      </select>
      <div className="navbar-nav">
        <div className="nav-item text-nowrap">
          <a className="nav-link px-3" href="/">
            Sign out
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;
