import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
// import Login from "./auth/login";
import MasterPage from "./main";
import ListProjects from "./project/ListProjects";
import Loginform from "./auth/Loginform";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Loginform />} />
        <Route path="/projects/:organization_id" element={<ListProjects />} />
        <Route path="/main/:project_id" element={<MasterPage />} />
      </Routes>
    </Router>
  );
}

export default App;
