import axios from "axios";

const Select = ({ field_id, fieldLabel, mappedEntityFieldId, defaultValues, data, setData, configuration
}) => {
  let selectboxValues = null;
  let isValidJson = false;
  try {
    selectboxValues = JSON.parse(JSON.parse(configuration).values);
    isValidJson = true;
  } catch (e) {
    isValidJson = false;
  }

  const handleChange = (name) => (event) => {
    const value = event.target.value;
    name = event.target.name;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <div className="form-group">
      <label htmlFor="selectbox">{fieldLabel}</label>
      {isValidJson && (
        <select className="form-control form-select" id={"form-field-" + field_id} name={mappedEntityFieldId}
          value={data[mappedEntityFieldId]}
          onChange={handleChange(data[mappedEntityFieldId])} >
          {selectboxValues != null &&
            selectboxValues.map((val, index) => (
              <option value={val[JSON.parse(configuration).nameField]} name={mappedEntityFieldId}>{val[JSON.parse(configuration).valueField]}</option>
            ))}
        </select>
      )}
    </div>
  );
};

export default Select;
