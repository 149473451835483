import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./navbar.css";
library.add(far, fas);

function NavigationBar({
  project_name,
  menu_items,
  getPageDetails,
  selectedMenu,
  setSelectedMenu,
}) {
  return (
    <div
      className="d-flex flex-column flex-shrink-0 p-3"
      style={{
        height: "100vh",
        overflow: "auto",
        background: "#202b46",
        color: "#fff",
      }}
    >
      <a
        href="/"
        class="d-block p-3 link-dark text-decoration-none"
        title="Icon-only"
        data-bs-toggle="tooltip"
        data-bs-placement="right"
      >
        <span class="fs-4" style={{ color: "#fff" }}>
          {project_name}
        </span>
      </a>
      <hr style={{ height: "1.5px", color: "#fff" }} />
      <ul className="nav nav-pills flex-column mb-auto">
        {menu_items &&
          menu_items.map((item, index) => {
            return (item.show && 
              <li className="nav-item">
                <Link
                  title={item.name + " - " + item.description}
                  onClick={() => {
                    sessionStorage.setItem("lastSelectedMenuName", item.name);
                    sessionStorage.setItem("lastSelectedMenuPage", item.pageId);
                    setSelectedMenu(item.name);
                    getPageDetails(item.pageId);
                  }}
                  className={
                    selectedMenu == item.name
                      ? "nav-link active text-limit"
                      : "nav-link text-white text-limit"
                  }
                  aria-current="page"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-right-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                    />
                  </svg>
                  <span style={{ marginLeft: "3%" }}></span>
                  {item.name}
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default NavigationBar;
