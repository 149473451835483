const Checkbox = ({ fieldLabel, defaultValues }) => {
  let checkboxValues = [];
  let isValidJson = false;
  defaultValues =
    '[{"id": "1", "name": "Male", "selected": false}, {"id": "1", "name": "Female", "selected": true}]';
  try {
    checkboxValues = JSON.parse(defaultValues);
    isValidJson = true;
  } catch (e) {
    isValidJson = false;
  }

  return (
    <div>
      <label htmlFor="radiobutton">{fieldLabel}</label>
      {isValidJson &&
        checkboxValues.map((val, index) => (
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={val.name}
              id={val.id}
            />
            <label className="form-check-label" for="flexCheckDefault">
              {val.name}
            </label>
          </div>
        ))}
    </div>
  );
};

export default Checkbox;
