import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import ReactPaginate from "react-paginate";
import { Input } from "@mui/icons-material";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ListPageLayout({
  listpagelayout,
  listpagedata,
  getPageDetails,
  pageCount,
  handlePageClick,
  // totalPages,
  // setTotalPages,
  // pageNumber,
  // setPageNumber,
  // isFirstPageNumber,
  // setIsFirstPageNumber,
  // isLastPageNumber,
  // setIsLastPageNumber,
  getListPageData,
  pageId,
  generateReport,
}) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, []);


  
  return (
    listpagelayout != null && (
      <div>
        <div className="row">
          <div className="col-md-9">
          <input
          type="text"
          field_id="searchbox"
          fieldLabel="Search"
          field_placeholder="Search term"
          onKeyUp={(event)=>getListPageData(pageId, 0, event.target.value)}
        />

          </div>
          <div className="col-md-3">
            {listpagelayout &&
              listpagelayout.buttons.map((item, index) => {
                return (
                  <button
                    className="btn btn-info float-right"
                    onClick={() => getPageDetails(item.redirectToPageId)}
                  >
                    {item.name}
                  </button>
                );
              })}
          </div>
        </div>

        <hr />

        <table className="table" style={{ background: "#fff" }}>
          <thead>
            <tr>
              {listpagelayout &&
                listpagelayout.listPageMainContent.map((item, index) => {
                  return <th>{item.fieldLabel}</th>;
                })}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {listpagedata &&
              listpagedata.map((item, index) => {
                return (
                  <tr>
                    {listpagelayout.listPageMainContent.map(
                      (itemMainContent) => {
                        return (
                          <td>
                            {
                              JSON.parse(item)[
                                itemMainContent.mappedEntityFieldId
                              ]
                            }
                          </td>
                        );
                      }
                    )}
                    <td>
                    {listpagelayout.listPageLinks.map((itemLink) => {
                      return (
                          <Link
                            onClick={() =>
                              itemLink.action == "report" ?  
                              generateReport(JSON.parse(listpagedata[index]), itemLink)
                              :
                              getPageDetails(
                                itemLink.redirectToPageId,
                                JSON.parse(listpagedata[index])
                              )
                            }
                          >
                            {itemLink.name}
                            <span style={{ marginLeft: '10px' }}></span>
                          </Link>
                        
                      );
                    })}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
         <ReactPaginate
            breakLabel="..."
            previousLabel="< previous"
            nextLabel="next >"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center "}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
            renderOnZeroPageCount={null}
          />
      </div>
    )
  );
}

export default ListPageLayout;
