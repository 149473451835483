import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import Header from "../common/header";
import ReactPaginate from "react-paginate";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ListProjects() {
  const { organization_id } = useParams();
  let i = 1;
  const navigate = useNavigate();
  const navigateMainPage = (project_id) => {
    navigate(`/main/${project_id}`);
  };
  let [loading, setLoading] = useState(false);
  let [projects, setProjects] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    getUserProjectsData();
  }, []);

  // List Projects
  const getUserProjectsData = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/project/organization/${organization_id}?page=0`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_info")).accessToken,
          },
        }
      )
      .then((response) => {
        if (response.data.numberOfElements == 1) {
          navigateMainPage(response.data.content[0].id);
        } else {
          setProjects(response.data.content);
          const total = response.data.totalElements;
          setPageCount(Math.ceil(total / 10));
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const fetchProjects = async (currentPage) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/project/organization/${organization_id}?page=${currentPage}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected;
    const dataFromServer = await fetchProjects(currentPage);
    setProjects(dataFromServer.content);
  };

  const handleChange = (event) => {
    const val = event.target.value;
    navigate(`/main/${val}`);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
        <div className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <h2>Projects</h2>
          <hr />
          <table className="table">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Project name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{i++}</td>
                    <td>{item.name}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => navigateMainPage(item.id)}
                      >
                        Use
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ReactPaginate
        breakLabel="..."
        previousLabel="< previous"
        nextLabel="next >"
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination justify-content-center "}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        renderOnZeroPageCount={null}
      />
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default ListProjects;
