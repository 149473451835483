import { Button } from "react-bootstrap";
import Checkbox from "./elementfiles/Checkbox";
import DatePicker from "./elementfiles/DatePicker";
import Input from "./elementfiles/Input";
import Radiobutton from "./elementfiles/Radiobutton";
import Select from "./elementfiles/Select";
import TextArea from "./elementfiles/TextArea";

const Element = ({
  field: {
    htmlComponentId,
    id,
    fieldLabel,
    field_placeholder,
    mappedEntityFieldId,
    defaultValues,
    isMandatoryField,
    configuration
  },
  data,
  setData,
  generateReportUsingButton
}) => {
  switch (htmlComponentId) {
    case "Textbox":
      return (
        <Input
          field_id={id}
          fieldLabel={fieldLabel}
          field_placeholder={field_placeholder}
          mappedEntityFieldId={mappedEntityFieldId}
          data={data}
          setData={setData}
          isMandatoryField={isMandatoryField}
        />
      );
    case "Selectbox":
      return (
        <Select
          field_id={id}
          fieldLabel={fieldLabel}
          mappedEntityFieldId={mappedEntityFieldId}
          defaultValues={defaultValues}
          data={data}
          setData={setData}
          configuration={configuration}
        />
      );
    case "Textarea":
      return (
        <TextArea
          field_id={id}
          fieldLabel={fieldLabel}
          field_placeholder={field_placeholder}
          mappedEntityFieldId={mappedEntityFieldId}
          data={data}
          setData={setData}
        />
      );
      case "Date Picker":
        return (
          <DatePicker
            field_id={id}
            fieldLabel={fieldLabel}
            field_placeholder={field_placeholder}
            mappedEntityFieldId={mappedEntityFieldId}
            data={data}
            setData={setData}  
          />
        );
    case "Radio button":
      return (
        <Radiobutton
          field_id={id}
          fieldLabel={fieldLabel}
          field_placeholder={field_placeholder}
          mappedEntityFieldId={mappedEntityFieldId}
          defaultValues={defaultValues}
          data={data}
          setData={setData}
        />
      );
    case "Checkbox":
      return (
        <Checkbox
          field_id={id}
          fieldLabel={fieldLabel}
          field_placeholder={field_placeholder}
          mappedEntityFieldId={mappedEntityFieldId}
          defaultValues={defaultValues}
          data={data}
          setData={setData}
        />
      );
    case "Element Group Title":
      return (
        <div>
          <h6 style={{ color: "darkblue" }}>{fieldLabel}</h6> <hr />
        </div>
      );
    case "Page Header":
      return (
        <div>
          <h3>{fieldLabel}</h3> <hr />
        </div>
      );
    case "Button":
      return (
        <Button
          field_id={id}
          fieldLabel={fieldLabel}
          onClick={generateReportUsingButton}
        >{fieldLabel}</Button>
      );
    default:
      return null;
  }
};
export default Element;
