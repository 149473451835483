import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
import Element from "./Element";
import ListPageLayout from "./ListPageLayout";
import ClockLoader from "react-spinners/ClockLoader";
import FileSaver from "file-saver";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function MasterPage() {
  const { project_id } = useParams();
  const navigate = useNavigate();
  let [loading, setLoading] = useState(true);
  let [projectDetails, setProjectDetails] = useState({});
  let [menuItems, setMenuItems] = useState([]);
  let [pageLayout, setPageLayout] = useState([]);
  let [pageDetails, setPageDetails] = useState({});
  const [data, setData] = useState({});
  const [listpagelayout, setListPageLayout] = useState(null);
  const [pageId, setPageId] = useState("");
  const [listPageData, setListPageData] = useState([]);
  let [selectedMenu, setSelectedMenu] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [showSaveButton, setShowSaveButton] = useState(true);

  useEffect(() => {
    setLoading(true);
    getProjectDetails();
    getMenuItems();
    setLoading(false);
  }, []);

  // Get Project Details
  const getProjectDetails = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/project/${project_id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setProjectDetails(response.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // Get Menu Items
  const getMenuItems = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/menu/project/${project_id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setMenuItems(response.data);
        if (response.data && response.data.length > 0 && selectedMenu === "") {
          const lastSelectedMenuPage = sessionStorage.getItem("lastSelectedMenuPage");
          const lastSelectedMenuName = sessionStorage.getItem("lastSelectedMenuName");
          if (!lastSelectedMenuPage) {
            lastSelectedMenuPage = response.data[0].pageId;
            sessionStorage.setItem("lastSelectedMenuPage", response.data[0].pageId);
          }
          if (!lastSelectedMenuName) {
            lastSelectedMenuName = response.data[0].pageId;
            sessionStorage.setItem("lastSelectedMenuName", response.data[0].name);
          }
          getPageDetails(lastSelectedMenuPage);
          setSelectedMenu(lastSelectedMenuName);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // Get Page Layout
  const getPageLayout = async (pageId, pageType) => {
    if (pageType != "list" || pageType != "List") {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/page-field/page/${pageId}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_info")).accessToken,
          },
        })
        .then((response) => {

          response.data.map((rowData, index) => {
            rowData.rowData.map((rowDataVal, indexRowDataVal) => {
              setShowSaveButton(rowDataVal.htmlComponentId == "Button");
            })
          })
          setPageLayout(response.data);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  // Get Page Details
  const getPageDetails = async (pageId, idOfData = null) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/page/${pageId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then(async (response) => {
        setPageDetails({});
        setPageDetails(response.data);
        setPageId(pageId);
        if (
          response.data.pageType == "list" ||
          response.data.pageType == "List"
        ) {
          setPageLayout([]);
          await getListPageLayoutData(response.data.id);
        } else {
          setListPageLayout(null);
          await getPageLayout(response.data.id, response.data.pageType);
        }
        await getEntityFields(response.data.mappedEntityId);
        if (idOfData) {
          await getDataById(pageId, idOfData._id.$oid);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // Get Page Layout
  const getDataById = async (pageId, entityId) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/document?projectId=${project_id}&pageId=${pageId}&fieldName=_id&fieldValue=` +
        entityId,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_info")).accessToken,
          },
        }
      )
      .then((response) => {
        let data = {};
        const resultFromApi = JSON.parse(response.data[0]);
        Object.keys(resultFromApi).map((dataItem) => {
          data[dataItem] = resultFromApi[dataItem];
        });
        setData(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // Get Page Layout
  const getEntityFields = async (entityId) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/entity-field/entity/${entityId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        let data = {};
        response.data.forEach((dataItem, index) => {
          data[dataItem.name] = "";
        });
        setData(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // Get list page layout
  const getListPageLayoutData = async (pageId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/list-page/page/${pageId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_info")).accessToken,
          },
        }
      );
      setListPageLayout(response.data);
      getListPageData(pageId);
    } catch (error) { }
  };

  // Get list page data
  const getListPageData = async (pageId, pageNumber = 0, searchTerm = "") => {
    try {
      const projectId = localStorage.getItem("project_id");
      if (searchTerm === "" || searchTerm.length >= 3) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/document/all/${projectId}/${pageId}?page=0&limit=10&searchTerm=${searchTerm}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("user_info")).accessToken,
            },
          }
        );
        setListPageData(response.data.data);
        console.log("response", response)
        const total = response.data.numberOfRecords;
        if (total != null) {
          setPageCount(Math.ceil(total / 10));
        }
        else {
          setPageCount(0);
        }
      }
    } catch (error) {
      console.log("error", error)
    }
  };

  const fetchListPageData = async (currentPage) => {
    setLoading(true);
    const projectId = localStorage.getItem("project_id");
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/document/all/${projectId}/${pageId}?page=${currentPage}&limit=10&searchTerm=`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      }
    );
    const data = await res.json();
    setLoading(false);
    return data;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected;
    const dataFromServer = await fetchListPageData(currentPage);
    setListPageData(dataFromServer.data);
  };

  const generateReportUsingButton = () => {
    Array.from(document.form.elements).forEach(function (formElement) {
      if(!data[formElement.name]){
        data[formElement.name] = formElement.value
      }
    });
    const dataToPost = { data: JSON.stringify(data), pageId: pageId };
    downloadReport(dataToPost);
  }

  const generateReport = (recordId = null, action = null) => {
    const dataToPost = { data: JSON.stringify(recordId), additionalDetails: JSON.stringify(action) }
    downloadReport(dataToPost);
  }

  const downloadReport = (dataToPost) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/report/generate`, dataToPost,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: '*/*',
            Authorization:
              'Bearer ' +
              JSON.parse(localStorage.getItem('user_info')).accessToken,
          },
          responseType: 'arraybuffer',
        }
      )
      .then((response) => {
        if (response.status === 200) {
          var blob = new Blob([response.data], {
            type: 'application/pdf',
          });
          FileSaver.saveAs(blob, 'report.pdf');
        }
      })
      .catch((error) => {
        // Handle error
      });
  };

  const submitData = () => {
    let isValid = true;
    // Loop over them and prevent submission
    Array.from(document.form.elements).forEach(function (formElement) {
      if (formElement.classList.value.includes('is-invalid')) {
        isValid = false;
      }
    });

    if (isValid) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/document`,
          { data: JSON.stringify(data), pageId: pageId },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("user_info")).accessToken,
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            setData("");
            toast.success("Added sucessfully", {
              position: toast.POSITION.TOP_CENTER,
            });
            const lastSelectedMenuPage = sessionStorage.getItem("lastSelectedMenuPage");
            getPageDetails(lastSelectedMenuPage)
          }
        })
        .catch((error) => { });
    } else {
      toast.error("Please fill required details", {
        position: toast.POSITION.TOP_CENTER,
      });

    }
  }

  return (
    <div class="container-fluid">
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />

      <div className="row">
        <div className="col-md-3">
          <NavigationBar
            project_name={projectDetails.name}
            menu_items={menuItems}
            getPageDetails={getPageDetails}
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
          />
        </div>
        <div className="col-md-9">
          <Header project_details={projectDetails} />
          <hr />
          {pageDetails.name && (
            <div className="row mt-2 mb-2" style={{ color: "#0b4c5a" }}>
              {" "}
              <h4>{pageDetails.name}</h4>
            </div>
          )}
          <hr />
          {pageLayout && pageLayout.length > 0 ? (
            <form
              name="form"
              className="row g-3 mt-2 needs-validation"
              style={{
                border: "dotted 1px #e7e7e7",
                borderRadius: "10px",
                background: "white",
              }}
              novalidate
            >
              {pageLayout.map((field, i) => (
                <div
                  id={"r_" + i}
                  className="row"
                  style={{ width: "100%" }}
                  key={i}
                >
                  {field.rowData
                    ? field.rowData.map((field_j, j) => (
                      <div
                        name={field_j.id}
                        key={j}
                        id={"r_" + i + "_c_" + j}
                        className={"col-md-" + field.columnSize}
                      >
                        <Element
                          field={field_j}
                          data={data}
                          setData={setData}
                          generateReportUsingButton={generateReportUsingButton}
                        />
                      </div>
                    ))
                    : null}
                </div>
              ))}
              {showSaveButton ? "" :
                <div className="col-md-9">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={submitData}
                  >
                    Save
                  </button>
                </div>
              }
            </form>
          ) : (
            <ListPageLayout
              listpagelayout={listpagelayout}
              listpagedata={listPageData}
              getPageDetails={getPageDetails}
              getListPageData={getListPageData}
              pageId={pageId}
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              generateReport={generateReport}
            />
          )}
        </div>
        <ToastContainer autoClose={2000} />
      </div>
    </div>
  );
}

export default MasterPage;
